<template>
  <div class="container-userInfo">
    <div class="header-user">
      <div class="information-user">
        <img
          id="img-container-userIcon"
          src="../assets/empty-user-icon.png"
          alt="user"
        >
        <div class="right-information">
          <label>{{ role }}</label>
          <label>{{ countInfo.firstName }} {{ countInfo.lastName }}</label>
          <label>{{ countInfo.email }}</label>
        </div>
      </div>
      <div>
        <h2 id="fecha"> {{ $dateString(dateDateStr) }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require('moment');

export default {
  data() {
    return {
      dateDateStr: null,
      roleId: null,
      countInfo: null,
      role: null,
    };
  },
  created() {
    const date = new Date();
    this.dateDateStr = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const roleIdLocalStorage = JSON.parse(localStorage.getItem('vuex'));
    this.countInfo = roleIdLocalStorage.auth.user;
    if (this.countInfo.role.name === 'RECEPCION') {
      this.role = 'RECEPCIÓN';
    } else {
      this.role = this.countInfo.role.name;
    }
    this.roleId = roleIdLocalStorage.auth.user.roleId;
  },
};
</script>

<style lang="scss">
.container-userInfo{
  padding: 10px;
  font-family: 'Poppins', sans-serif;
}
.header-user {
  display: flex;
  justify-content: space-between;
  #fecha {
    color: #8F70C7;
  }
}
.information-user {
  display: flex;
  align-items: center;
  gap: 1rem;
  .right-information {
    display: flex;
    flex-direction: column;
    label {
      color: rgb(65, 65, 65);
      font-weight: bold;
      font-size: 16px;
    }
  }
}
#img-container-userIcon {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
</style>
